// Pagination.js
import React from "react";
import "./Pagination.css";

const Pagination = ({ currentPage, totalPages, handlePageClick }) => {
  return (
    <div className="pagination-container">
      <div className="pagination">
        <button
          onClick={() => handlePageClick(currentPage > 0 ? currentPage - 1 : 0)}
        >
          Previous
        </button>
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => handlePageClick(index)}
            className={`pagination-button ${
              currentPage === index ? "active" : ""
            }`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() =>
            handlePageClick(
              currentPage < totalPages - 1 ? currentPage + 1 : currentPage
            )
          }
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
