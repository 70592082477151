import React from "react";
import "./Header.css";
export default function Header() {
  return (
    <>
      <div>
        <nav class="navbar navbar-default navbar-static-top m-b-0 ">
          <div class="navbar-header left">
            <div class="top-left-part left-part">
              <div className="logo-div">
                <img
                  className="img  "
                  src="assets/images/nordiqo_logo.png"
                  alt="logo"
                />
                {/* <div className="logo">GYM</div> */}
                {/* <div className="logo-text"> MANAGEMENT</div> */}
              </div>
              <div className="toggle-button"></div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
