import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./components/pages/login/Login";
import Dashboard from "./components/pages/dashboard/Dashboard";
import User from "./components/pages/user/User";
import Accounts from "./components/pages/accounts/account";
import BankList from "./components/pages/BankList/Bank";
import Category from "./components/pages/Category/category";
import Transaction from "./components/pages/Transaction/Transaction";
import Auth from "./components/Auth/Auth";
import AuthSuccess from "./components/pages/AuthSuccessScreen/AuthSuccess";
import AuthComponent from "./components/Auth/Auth";
import ProtectedRoute from "./components/Auth/Protected";
import Deleted from "./components/pages/deletedUser/deletedUser";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <AuthComponent>
                <Login />
              </AuthComponent>
            }
          />
          <Route path="/success" element={<AuthSuccess />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/user" element={<User />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/banklist" element={<BankList />} />
            <Route path="/deleteuser" element={<Deleted />} />
            <Route path="/category" element={<Category />} />
            <Route path="/transaction" element={<Transaction />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
