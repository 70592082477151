const baseUrl = "https://gateway.nordiqo.com/api/admin/";
export default {
  // Admin Login
  Admin_Login: baseUrl + "login",
  Account_list: baseUrl + "getaccountslist/10",
  Bank_list: baseUrl + "banklist/50",
  Category_list: baseUrl + "categorylist/30",
  User_list: baseUrl + "getuserlist/10",
  Total_Records: baseUrl + "gettotalrecord",
  userdelete:baseUrl + "userdelete/",
  Admin_Delete: baseUrl + "userdeletelist",
};
