import React from "react";
import "./authSuccess.css";

const AuthSuccess = () => {
  return (
    <div id="wrapper">
      <div className="container">
        <div className="success-header-container">
          <div className="success-inner-header" style={{ paddingLeft: "25px" }}>
            <div className="success-logo">
              <img
                src="assets/images/success-round.png"
                className="nordiqo-logo"
              />
              <img
                className="nordiqo-logo"
                src="assets/images/nordiqo_logo.png"
                alt="logo"
                style={{ width: "160px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="main-body">
        <div id="main-content">
          <div className="body_bg_top_right_center">
            <div className="container">
              <div className="row congratulations_center">
                <div className="col-md-12 text-center">
                  <h1 className="h1-basic01">Success!</h1>
                  <h4 className="h4-basic01">
                    Thank you for verifying your <br />
                    financial information!
                  </h4>
                  <div className="figure">
                    <img src="assets/images/success-true.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="default-bank-connection-result">
            <img
              src="assets/images/connection-image.png"
              className="connection-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthSuccess;
