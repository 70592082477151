import React from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import baseUrl from "../../services/WebUrls";
import "./Login.css";

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Required";
  } else if (!/^(?=.*\d)[a-zA-Z0-9]+$/.test(values.password.trim())) {
    errors.password = "Password must contain at least one numeric digit";
  }

  return errors;
};

const Login = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: async (values) => {
      console.log(values);
      try {
        const response = await axios.post(baseUrl.Admin_Login, values);
        console.log(response);
        const { status, data } = response;
        if (status === 200) {
          if (data && data.status === 1) {
            if (data.token) {
              localStorage.setItem("authToken", data.token);
              toast.success("Sign In successful!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1000,
              });
              setTimeout(() => {
                navigate("/dashboard");
              }, 1000);
            }
          } else {
            toast.error(data.message, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
            });
          }
        } else {
          toast.error("Invalid credentials!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
      } catch (error) {
        console.error("Error during login:", error);
        toast.error("An error occurred during login", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      }
    },
  });

  return (
    <>
      <div id="wrapper" className="login-register">
        <div className="box">
          <div className="d-flex justify-content-center align-items-center p-3">
            <img
              className="img mb-4"
              src="assets/images/nordiqo_logo.png"
              alt="logo"
            />
          </div>

          <h4 style={{ color: "white" }}>Admin Sign In</h4>

          <form onSubmit={formik.handleSubmit}>
            <div className="inputBox">
              <input
                id="email"
                name="email"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              <label htmlFor="email" style={{ color: "white" }}>
                Email
              </label>
              {formik.errors.email ? (
                <div className="input-error">{formik.errors.email}</div>
              ) : null}
            </div>

            <div className="inputBox">
              <input
                id="password"
                name="password"
                type="password"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              <label htmlFor="password" style={{ color: "white" }}>
                Password
              </label>
              {formik.errors.password ? (
                <div className="input-error">{formik.errors.password}</div>
              ) : null}
            </div>

            <h4>
              <button
                className="btn btn-lg"
                type="submit"
                style={{ backgroundColor: "#109899" }}
              >
                <b style={{ color: "white" }}>Submit</b>
              </button>
            </h4>
          </form>
          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default Login;
