// AuthComponent.js
import React from "react";
import { Navigate } from "react-router-dom";

const AuthComponent = ({ children }) => {
  const token = localStorage.getItem("authToken");

  if (token) {
    return <Navigate to="/dashboard" />;
  }
  return children;
};

export default AuthComponent;
