import React, { useRef } from "react";
import Navbar from "../../comman/header/navbar/Navbar";

import { useState, useEffect, useMemo } from "react";
import Header from "../../comman/header/header/Header";
import {} from "react-bootstrap";
import axios from "axios";
// import "./User.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import { useNavigate, Link } from "react-router-dom";
import baseUrl from "../../services/WebUrls";
// import ViewUser from "../adduser/view-user/ViewUser";
import Swal from "sweetalert2";

export default function Deleted() {
  const [user, setUser] = useState([]);

  const [closeModal, setCloseModal] = useState(false);
  const [title, setTitle] = useState([]);
  const [description, setDescription] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [filterVal, setFilterVal] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [subscriptiondata, setSubscriptiondata] = useState();
  const [hide, setHide] = useState(false);
  const [Toatalpage, setToatalpage] = useState([1, 2, 3]);
  const [record, setRecord] = useState([]);
  const [fetchdata, setFetchdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage] = useState(10);

  const navigate = useNavigate();

  const getFetchData = async () => {
    try {
      const response = await axios.get(baseUrl.Admin_Delete);
      setFetchdata(response.data.data);
      // setFetchdata(response.data.data.data[0].accountList);/
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // Handle the error as needed
    }
  };

  useEffect(() => {
    getFetchData();
  }, []);

//   const filteredData = fetchdata.filter((row) =>
//     row.display_name.toLowerCase().includes(filterVal.toLowerCase()),
   
//   );
  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

//   const offset = currentPage * perPage;
//   const paginatedData = filteredData;

  // console.log(fetchdata);
  const handleClick = async (userId) => {
    console.log(userId,"id")
    Swal.fire({
      title: "Are You Sure to Delete Account?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete Account",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${baseUrl.userdelete}${userId}`)
          .then((response) => {
            toast.success("User deleted successfully");
            console.log(response,"response")
            getFetchData(); // Refresh the user list after deletion
          })
          .catch((error) => {
            toast.error("Failed to delete user");
          });
      }
    });
  };
  return (
    <>
      <div>
        <div style={{ marginTop: "10px" }}>
          <Header />
          <Navbar />
        </div>
        <ToastContainer />
        <div id="page-wrapper">
          <div
            className="col-lg-12 col-sm-12 col-xs-12"
            style={{ marginTop: "20px" }}
          >
            <div className="table-responsive" style={{ margin: "1px" }}>
              <div className="title">
                <div>Deleted User list</div>
                <div>
                  <span>
                    <Link to="/dashboard"> Dashboard</Link>
                  </span>
                  <span> /Deletedlist </span>
                </div>
              </div>
              <hr></hr>
              <div>
                <div className="table-responsive panel white-box radius">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="panel-body">
                        <div className="header-content">
                          <div className="col-md-3">
                            <div className="form-group">
                              <div className="input-icons">
                                <i className="fa fa-search icon"></i>
                                <input
                                  type="search"
                                  value={filterVal}
                                  placeholder="search..."
                                  className="form-control"
                                  onChange={(e) => setFilterVal(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <table className="table table-bordered  table-hover manage-u-table">
                    <thead>
                      <tr
                        className="table_th_font thBack"
                        style={{ backgroundColor: "#F08000" }}
                      >
                        <th scope="col" className="text-center">
                          <b>S.No</b>
                        </th>
                        <th scope="col" className="text-center">
                          <b>Email</b>
                        </th>
                        <th scope="col" className="text-center">
                          <b>User Name</b>
                        </th>
                        <th scope="col" className="text-center">
                          <b>Deactivate Acc. Request</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fetchdata.map((row, i) => (
                        <tr
                          key={i}
                          className="table_th_font bg-light text-dark"
                        >
                          <td scope="col" className="text-center text-black">
                            {i + 1}
                          </td>
                          <td scope="col" className="text-center text-black">
                            {row.email}
                          </td>
                          <td scope="col" className="text-center text-black">
                            {row.name}
                          </td>
                          <td scope="col" className="text-center">
                           <button style={{border: 'none',fontWeight: 'bold', backgroundColor:'#cf352e', color: 'white', padding: '3px 14px'}}  onClick={() => handleClick(row.user_id)}>Delete Account</button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="pagination-container">
                    <ReactPaginate
                      previousLabel={"← Previous"}
                      nextLabel={"Next →"}
                      breakLabel={"..."}
                      pageCount={Math.ceil(fetchdata.length / perPage)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
