import React, { useEffect, useState } from "react";
import Navbar from "../../comman/header/navbar/Navbar";
// import Footer from "../../comman/footer/Footer";
import Header from "../../comman/header/header/Header";
import axios from "axios";
import WebUrls from "../../services/WebUrls";
import { header } from "../../comman/common";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import baseUrl from "../../services/WebUrls";

export default function Dashboard() {
  // const [totalcount, setTotalcount] = useState({users:0,product:0,trainer:0,subscription:0})
  const [totaluser, setTotaluser] = useState([1, 2]);
  const [totalproduct, setTotalproduct] = useState(0);
  const [totaltrainer, setTotaltrainer] = useState(0);
  const [totalsubscription, setTotalsubscription] = useState(0);
  const [data, setData] = useState("");

  const [state, setState] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
      },
    },
    series: [
      {
        name: "series-1",
        data: [10, 20, 30, 40, 50, 60, 70, 80, 100],
      },
    ],
  });

  const getcount = async () => {
    const response = await axios.get(baseUrl.Total_Records);
    setData(response.data.data.data);
    console.log(response.data.data.data, "respnos++++");
  };

  let show = false;
  useEffect(() => {
    getcount();
  }, []);

  return (
    <>
      {show ? (
        <>
          <div style={{ marginTop: "10px" }}>
            {/* <span className="loader"></span> */}

            <Header />
            <Navbar />
          </div>
        </>
      ) : (
        <div className="backgroundAndHeight">
          <div style={{ marginTop: "10px" }}>
            <Header />
            <Navbar />
          </div>
          <div>
            <div id="page-wrapper">
              <div className="backgroundAndHeight">
                <div className="container-fluid">
                  <div className="row" style={{ marginTop: "40px" }}>
                    <div className="col-lg-4 col-sm-6 col-xs-9">
                      <div
                        className="white-box radius"
                        style={{ backgroundColor: "#004c4c" }}
                      >
                        <Link to="/user">
                          <h3 className="box-title text-white">
                            <b>TOTAL USERS</b>
                          </h3>
                          <ul className="list-inline two-part d-flex justify-content-between">
                            <li>
                              <i className="icon-people "></i>
                            </li>
                            <li className="text-right">
                              <span className="counter">
                                {data.totalUsers || "-"}
                              </span>
                            </li>
                          </ul>
                        </Link>
                      </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-xs-9 ">
                      <div
                        className="white-box radius"
                        style={{ backgroundColor: "#004c4c" }}
                      >
                        <Link to="/banklist">
                          <h3 className="box-title text-white ">TOTAL Banks</h3>
                          <ul className="list-inline two-part d-flex justify-content-between">
                            <li>
                              <i class="fas fa-warehouse"></i>
                            </li>
                            <li className="text-right">
                              <span className="counter">
                                {data.totalBankList || "-"}
                              </span>
                            </li>
                          </ul>
                        </Link>
                      </div>
                    </div>
                    {/* <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div
                        className="white-box radius"
                        style={{ backgroundColor: "#004c4c" }}
                      >
                        <Link to="/accounts">
                          <h3 className="box-title text-white">
                            TOTAL Accounts
                          </h3>
                          <ul className="list-inline two-part d-flex justify-content-between">
                            <li>
                              <i class="fa fa-user" aria-hidden="true"></i>
                            </li>
                            <li className="text-right">
                              <span className="">
                                {data.totalAccounts || "-"}
                              </span>
                            </li>
                          </ul>
                        </Link>
                      </div>
                    </div> */}
                    <div className="col-lg-4 col-sm-6 col-xs-9 ">
                      <div
                        className="white-box radius"
                        style={{ backgroundColor: "#004c4c" }}
                      >
                        <Link to="/category">
                          <h3 className="box-title text-white">
                            Total Category
                          </h3>
                          <ul className="list-inline two-part d-flex justify-content-between">
                            <li>
                              <i class="far fa-credit-card"></i>
                            </li>
                            <li className="text-right">
                              <span className="">
                                {data.totalCategoryList || "-"}
                              </span>
                            </li>
                          </ul>
                        </Link>
                        {/* {console.log(totalcount)} */}
                      </div>
                    </div>
                  </div>

                  {/* start of thr charts */}
                  <div className="row" style={{ marginTop: "30px" }}>
                    <div className="col-4">
                      <Chart
                        options={state.options}
                        series={state.series}
                        type="bar"
                        width="500"
                      />
                    </div>
                    <div className="col-4 offset-2">
                      <Chart
                        options={state.options}
                        series={state.series}
                        type="area"
                        width="500"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
