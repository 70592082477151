import React, { useRef } from "react";
import Navbar from "../../comman/header/navbar/Navbar";
import { useState, useEffect, useMemo } from "react";
import Header from "../../comman/header/header/Header";
import {} from "react-bootstrap";
import axios from "axios";
import "./category.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import WebUrls from "../../services/WebUrls";
import Pagination from "../../comman/pagination/Pagination.jsx";
import "../../comman/pagination/Pagination.css";


export default function Category() {
  const [user, setUser] = useState([]);

  const [closeModal, setCloseModal] = useState(false);
  const [title, setTitle] = useState([]);
  const [description, setDescription] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [filterVal, setFilterVal] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [subscriptiondata, setSubscriptiondata] = useState();
  const [hide, setHide] = useState(false);
  const [Toatalpage, setToatalpage] = useState([1, 2, 3]);
  const [record, setRecord] = useState([]);
  const [fetchdata, setFetchdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage] = useState(10);

  const navigate = useNavigate();

  const getFetchData = async () => {
    try {
      const response = await axios.get(WebUrls.Category_list);

      setFetchdata(response.data.data.data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
      // Handle the error as needed
    }
  };

  useEffect(() => {
    getFetchData();
  }, []);

  const filteredData = fetchdata.filter((row) =>
    row.name.toLowerCase().includes(filterVal.toLowerCase())
  );

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const offset = currentPage * perPage;
  const paginatedData = filteredData.slice(offset, offset + perPage);
  // console.log(fetchdata);
  return (
    <>
      <div>
        <div style={{ marginTop: "10px" }}>
          <Header />
          <Navbar />
        </div>
        <ToastContainer />
        <div id="page-wrapper">
          <div
            className="col-lg-12 col-sm-12 col-xs-12"
            style={{ marginTop: "20px" }}
          >
            <div className="table-responsive" style={{ margin: "1px" }}>
              <div className="title">
                <div>Category</div>
                <div>
                  <span>
                    <Link to="/dashboard"> Dashboard</Link>
                  </span>
                  <span> / Category </span>
                </div>
              </div>
              <hr></hr>
              <div>
                <div
                  className="table-responsive panel white-box radius"
                  style={{ height: "100%" }}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="panel-body">
                        <div className="header-content">
                          <div className="col-md-3">
                            <div className="form-group">
                              <div className="input-icons">
                                <i className="fa fa-search icon"></i>
                                <input
                                  type="search"
                                  value={filterVal}
                                  placeholder="search..."
                                  className="form-control"
                                  onChange={(e) => setFilterVal(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <table className="table table-bordered  table-hover manage-u-table">
                    <thead>
                      <tr
                        className="table_th_font thBack"
                        style={{ backgroundColor: "#F08000" }}
                      >
                        <th scope="col" className="text-center">
                          S.No
                        </th>
                        <th scope="col" className="text-center">
                          Id
                        </th>
                        <th scope="col" className="text-center">
                          Category Name
                        </th>
                        <th scope="col" className="text-center">
                          Transaction Type
                        </th>
                        <th scope="col" className="text-center">
                          Parent Category Id
                        </th>

                        <th scope="col" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData.map((row, i) => (
                        <tr key={i} className="table_th_font bg-light  thBack">
                          <td scope="col" className="text-center text-dark">
                            {/* */}
                            {currentPage * perPage + i + 1}
                          </td>
                          <td scope="col" className="text-center text-dark">
                            {row.id}
                          </td>
                          <td scope="col" className="text-center text-dark">
                            {row.name}
                          </td>
                          <td scope="col" className="text-center text-dark">
                            {row.transaction_type}
                          </td>
                          <td scope="col" className="text-center text-dark">
                            {row.parent_category_id === null
                              ? "not available"
                              : row.parent_category_id}
                          </td>

                          <td scope="col" className="text-center text-dark">
                            Action
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={Math.ceil(filteredData.length / perPage)}
                    handlePageClick={handlePageClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
