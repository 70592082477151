import React, { useRef } from "react";
import Navbar from "../../comman/header/navbar/Navbar";

import { useState, useEffect, useMemo } from "react";
import Header from "../../comman/header/header/Header";
import {} from "react-bootstrap";
import axios from "axios";
// import "./User.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate, Link } from "react-router-dom";
// import ViewUser from "../adduser/view-user/ViewUser";

export default function Transaction() {
  const [user, setUser] = useState([]);

  const [closeModal, setCloseModal] = useState(false);
  const [title, setTitle] = useState([]);
  const [description, setDescription] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [filterVal, setSetFilterVal] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [subscriptiondata, setSubscriptiondata] = useState();
  const [hide, setHide] = useState(false);
  const [Toatalpage, setToatalpage] = useState([1, 2, 3]);
  const [record, setRecord] = useState([]);
  const [fetchdata, setFetchdata] = useState([]);

  const navigate = useNavigate();

  // const getFetchData = async () => {
  //   try {
  //     const response = await axios
  //       .get
  //       // "http://localhost:4000/api/v1/admin/getaccountslist/3"
  //       ();

  //     setFetchdata(response.data.data.data[0].accountList);
  //   } catch (error) {
  //     console.error("Error fetching data:", error.message);
  //     // Handle the error as needed
  //   }
  // };

  useEffect(() => {
    // getFetchData();
  }, []);

  console.log(fetchdata);
  return (
    <>
      <div>
        <div style={{ marginTop: "10px" }}>
          <Header />
          <Navbar />
        </div>
        <ToastContainer />
        <div id="page-wrapper">
          <div
            className="col-lg-12 col-sm-12 col-xs-12"
            style={{ marginTop: "20px" }}
          >
            <div className="table-responsive" style={{ margin: "1px" }}>
              <div className="title">
                <div>Transaction List</div>
                <div>
                  <span>
                    <Link to="/dashboard"> Dashboard</Link>
                  </span>
                  <span> /Transaction List </span>
                </div>
              </div>
              <hr></hr>
              <div>
                <div
                  className="table-responsive panel white-box radius"
                  style={{ height: "100vh" }}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="panel-body">
                        <div className="header-content">
                          <div className="col-md-3">
                            <div className="form-group">
                              <div className="input-icons">
                                <i className="fa fa-search icon"></i>
                                <input
                                  type="search"
                                  value={filterVal}
                                  placeholder="search..."
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <table className="table table-bordered  table-hover manage-u-table">
                    <thead>
                      <tr
                        className="table_th_font thBack"
                        style={{ backgroundColor: "#F08000" }}
                      >
                        <th scope="col" className="text-center">
                          S.No
                        </th>
                        <th scope="col" className="text-center">
                          Account Number
                        </th>
                        <th scope="col" className="text-center">
                          Account Name
                        </th>
                        <th scope="col" className="text-center">
                          Holder Name
                        </th>
                        <th scope="col" className="text-center">
                          Balance
                        </th>

                        <th scope="col" className="text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {fetchdata?.map((row, i) => (
                        <tr key={i} className="table_th_font bg-light thBack">
                          <td scope="col" className="text-center">
                            {/* */}
                            {i + 1}
                          </td>
                          <td scope="col" className="text-center text-dark">
                            {row.account_number}
                          </td>
                          <td scope="col" className="text-center">
                            {row.name_on_account}
                          </td>
                          <td scope="col" className="text-center">
                            {row.holder_name}
                          </td>
                          <td scope="col" className="text-center">
                            {row.balance}
                          </td>

                          <td scope="col" className="text-center">
                            Action
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
