import React from "react";
import Header from "../header/Header";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./Navbar.css";
import Swal from "sweetalert2";

export default function Navbar() {
  const navigate = useNavigate();
  const handleClick = async () => {
    Swal.fire({
      title: "Are You Sure To Logout ?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Log Out",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("authToken");
        navigate("/");
      }
    });
  };
  return (
    <>
      <div>
        <div
          className="navbar-default sidebar"
          role="navigation"
          style={{ marginTop: "59px" }}
        >
          <div className="sidebar-nav slimscrollsidebar">
            <ul className="nav" id="side-menu">
              <ul className="nav nav-second-level margin">
                <li>
                  <NavLink to="/dashboard" activeClassName="active-link">
                    <i className="fa fa-home"></i>
                    <span>&nbsp;&nbsp;&nbsp;Dashboard</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active-link" to="/user">
                    <i className="fa fa-user"></i>
                    <span>&nbsp;&nbsp;&nbsp;User</span>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink activeClassName="active-link" to="/accounts">
                    <i className="fa fa-clone"></i>
                    <span>&nbsp;&nbsp;&nbsp;Accounts</span>
                  </NavLink>
                </li> */}
                <li>
                  <NavLink activeClassName="active-link" to="/banklist">
                    <i className="fa fa-clone"></i>
                    <span>&nbsp;&nbsp;&nbsp;Banks</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active-link" to="/category">
                    <i className="fa fa-list "></i>
                    <span>&nbsp;&nbsp;&nbsp;Category</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink activeClassName="active-link" to="/deleteuser">
                    <i className="fa fa-clone"></i>
                    <span>&nbsp;&nbsp;&nbsp;Delete Request</span>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink activeClassName="active-link" to="/event">
                    <i className="fa fa-calendar"></i>
                    <span>&nbsp;&nbsp;&nbsp;Event</span>
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink activeClassName="active-link" to="/notification">
                    <i className="fa fa-bell"></i>
                    <span>&nbsp;&nbsp;&nbsp;Broadcast</span>
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink activeClassName="active-link" to="/trainer">
                    <i className="fa fa-users"></i>
                    <span>&nbsp;&nbsp;&nbsp;Trainers</span>
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink activeClassName="active-link" to="/support">
                    <i className="fa fa-wrench"></i>
                    <span>&nbsp;&nbsp;&nbsp;Support</span>
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink className="active-link" to={`/changepassword`}>
                    <i class="fas fa-upload"></i>
                    <span>&nbsp;&nbsp;&nbsp;Change Password</span>
                  </NavLink>
                </li> */}
                <li>
                  <nav className=" logout" onClick={handleClick}>
                    <a aria-current="page" className="active-link">
                      <i class="fas fa-sign-out-alt"></i>
                      &nbsp;&nbsp;&nbsp;
                      <span>&nbsp;&nbsp;&nbsp;Logout</span>
                    </a>
                  </nav>
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
